/* eslint-disable react/display-name */
import Link from 'next/link';
import { memo, useState, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { useRouter } from 'next/router';

import ErrorNotification from 'src/components/ErrorNotification';
import { A, ButtonTextBig, Caption, H1, H2, Text1 } from 'src/components/Text';
import { RoutePath } from 'src/constants/route-path';
import { StepProps } from 'src/containers/Quiz/models';

import { getCookie } from 'src/utils/cookie';

import {
  GoalBtn,
  GoalBtns,
  PrivacyCheckbox,
  PrivacyWrapper,
  StepHealthWellnessApps,
  StepHealthWellnessChoices,
  StepHealthWellnessChoicesContent,
  StepHealthWellnessWrapper,
  EmailConfirmCheckbox,
  EmailConfirmWrapper,
} from './step-health-wellness-styled';

export const StepHealthWellness = memo(
  ({ data, onSubmit, showEmailCheckbox }: StepProps): JSX.Element => {
    const router = useRouter();
    const { t } = useTranslation('quiz');

    const [privacyChecked, togglePrivacy] = useState(true);
    const [emailChecked, toggleEmail] = useState(true);

    // const [isUtm, setIsUtm] = useState(false);
    // const [isPP, setIsPP] = useState(false);

    useEffect(() => {
      if (Object.values(router.query).length <= 2) {
        toggleEmail(false);
        togglePrivacy(false);
      }
    }, []);

    return (
      <StepHealthWellnessWrapper>
        <StepHealthWellnessApps />

        <StepHealthWellnessChoices>
          <StepHealthWellnessChoicesContent>
            <H1 className="md-only">{data.title}</H1>
            <H2 className="sm-only">{data.title}</H2>
            {data.subtitle && <Text1>{data.subtitle}</Text1>}
            <GoalBtns>
              {data.nav.map((nav): JSX.Element[] =>
                data.selects[nav.id].options.map(
                  (option): JSX.Element => (
                    <GoalBtn
                      disabled={!privacyChecked}
                      key={option.id}
                      type="button"
                      onClick={
                        privacyChecked
                          ? (): void =>
                              onSubmit({
                                formValue: { [option.id]: option.value },
                              })
                          : undefined
                      }
                    >
                      <ButtonTextBig>{option.title}</ButtonTextBig>
                    </GoalBtn>
                  )
                )
              )}
            </GoalBtns>
            <PrivacyWrapper>
              <PrivacyCheckbox
                className={privacyChecked ? 'checked' : ''}
                role="button"
                onClick={(): void => {
                  togglePrivacy(!privacyChecked);
                }}
              />
              <Caption>
                {t('goal.agreement')}{' '}
                <Link
                  href={`${RoutePath.termsOfService}`}
                  passHref
                  prefetch={false}
                >
                  <A>{t('goal.terms')}</A>
                </Link>
                ,{' '}
                <Link
                  href={`${RoutePath.privacyPolicy}`}
                  passHref
                  prefetch={false}
                >
                  <A>{t('goal.privacy')}</A>
                </Link>
                ,{' '}
                <Link
                  href={`${RoutePath.subscriptionTerms}`}
                  passHref
                  prefetch={false}
                >
                  <A>{t('goal.subscription')}</A>
                </Link>
                {', '}
                <Link
                  href={`${RoutePath.cookiePolicy}`}
                  passHref
                  prefetch={false}
                >
                  <A>{t('goal.cookiePolicy')}</A>
                </Link>
              </Caption>
            </PrivacyWrapper>
            {showEmailCheckbox && (
              <EmailConfirmWrapper>
                <EmailConfirmCheckbox
                  className={emailChecked ? 'checked' : ''}
                  role="button"
                  onClick={(): void => {
                    toggleEmail(!emailChecked);
                  }}
                />
                <Caption>
                  {t('goal.email')}
                  {process.env.NEXT_PUBLIC_MIRROR_TITLE || 'Unimeal'}{' '}
                  {t('goal.emailEnd')}
                </Caption>
              </EmailConfirmWrapper>
            )}
            {!privacyChecked && (
              <ErrorNotification>{t('goal.error')}</ErrorNotification>
            )}
          </StepHealthWellnessChoicesContent>
        </StepHealthWellnessChoices>
      </StepHealthWellnessWrapper>
    );
  }
);

export default StepHealthWellness;
